(function() {
    'use strict';

    angular.module('ionicLogin', [
        'ionic',
        'ionicAlert',
        'ionicData',
        'ionicLoadingTemplate',
        'ionicOffline',
        'ionicRequest',
        'ngCordova',
        'uabDefaultVariable',
        'uabEnvironment',
        'uabRedirect',
        'uabRegistry'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicLogin').service('LoginService', LoginService);

    LoginService.$inject = [
        'DataManagementService',
        'DefaultVariableService',
        'EnvironmentService',
        'ErrorService',
        'OfflineService',
        'PromiseManagementService',
        'RegistryService',
        'RequestManagementService',
        '$window'
    ];

    function LoginService(
        DataManagementService,
        DefaultVariableService,
        EnvironmentService,
        ErrorService,
        OfflineService,
        PromiseManagementService,
        RegistryService,
        RequestManagementService,
        $window
    ) {
        var LoginService = this;

        var ON_LOGIN = 'onLogin';
        var ON_LOGOUT = 'onLogout';

        LoginService.getParams = getParams;
        function getParams() {
            var userAgent = $window.navigator.userAgent;

            return [
                'userAgent=' + userAgent,
                'Images',
                'Groups',
                'Locations.States',
                'Profile',
                'Projects',
                'Tags',
                'UserTypes.Features'
            ];
        }

        LoginService.checkAuth = checkAuth;
        function checkAuth() {
            LoginService.isChecking = true;

            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'auth');
            options = RequestManagementService.setAction(options, 'checkAuth');

            options = RequestManagementService.setParams(
                options,
                LoginService.getParams()
            );

            options = RequestManagementService.setMethod(options, 'POST');

            return DataManagementService.request(
                options
            ).then(
                function(data) {
                    if (data) {
                        var activeUser = data.users[0];
                        var token = data.token;

                        LoginService.setActiveUser(activeUser);
                        LoginService.setToken(token);

                        return LoginService.activeUser;
                    } else {
                        return false;
                    }
                }
            ).finally(
                function () {
                    LoginService.isChecking = false;
                }
            );
        }

        LoginService.checkResponse = checkResponse;
        function checkResponse(data) {
            var statusCode = DefaultVariableService.getInteger(
                data.status
            );

            if (statusCode === 401) {
                LoginService.onLogoutSuccess();
            }
        }

        LoginService.deleteActiveUser = deleteActiveUser;
        function deleteActiveUser() {
            window.localStorage.removeItem('activeUser');
        }

        LoginService.deleteToken = deleteToken;
        function deleteToken() {
            LoginService.activeUser.token = null;

            window.localStorage.removeItem('token');
        }

        LoginService.getActiveUser = getActiveUser;
        function getActiveUser() {
            var debugId = EnvironmentService.get('active_user_id');
            if (debugId) {
                return LoginService.loadActiveUser(debugId);
            } else {
                var response = false;
                if (LoginService.isActiveUserOnModel()) {
                    response = LoginService.activeUser;
                } else if (LoginService.isActiveUserInStorage()) {
                    response = LoginService.getActiveUserFromStorage();
                } else if (LoginService.hasToken()) {
                    return LoginService.checkAuth();
                }

                return PromiseManagementService.generateSuccess(response);
            }
        }

        LoginService.getActiveUserType = getActiveUserType;
        function getActiveUserType() {
            return LoginService.getActiveUser().then(
                function() {
                    return DefaultVariableService.getObject(
                        LoginService.activeUser.user_type
                    );
                }
            );
        }

        LoginService.getActiveUserFromStorage = getActiveUserFromStorage;
        function getActiveUserFromStorage() {
            return JSON.parse(localStorage.getItem('activeUser'));
        }

        LoginService.getRegisterOptions = getRegisterOptions;
        function getRegisterOptions(options) {
            var defaultOptions = {
                init:     true
            };
            defaultOptions[ON_LOGIN] = 'init';
            defaultOptions[ON_LOGOUT] = 'clear';

            return DefaultVariableService.mergeObjects(options, defaultOptions);
        }

        LoginService.getToken = getToken;
        function getToken() {
            var token = null;
            if (LoginService.isTokenOnModel()) {
                token = LoginService.activeUser.token;
            } else {
                token = LoginService.getTokenFromStorage();
            }

            if (token === null && LoginService.isLoggedIn()) {
                LoginService.onLogoutSuccess();
            }

            return token;
        }

        LoginService.getTokenFromStorage = getTokenFromStorage;
        function getTokenFromStorage() {
            return window.localStorage.getItem('token');
        }

        LoginService.hasActiveUser = hasActiveUser;
        function hasActiveUser() {
            return LoginService.isActiveUserOnModel() || LoginService.isActiveUserInStorage();
        }

        LoginService.hasToken = hasToken;
        function hasToken() {
            return LoginService.isTokenOnModel() || LoginService.isTokenInStorage();
        }

        LoginService.isCheckingAuth = isCheckingAuth;
        function isCheckingAuth() {
            return LoginService.isChecking;
        }

        LoginService.isLoggedIn = isLoggedIn;
        function isLoggedIn() {
            return LoginService.hasToken() && LoginService.hasActiveUser();
        }

        LoginService.isLoggingIn = isLoggingIn;
        function isLoggingIn() {
            return LoginService.isLoggingInFlag;
        }

        LoginService.isLoggingOut = isLoggingOut;
        function isLoggingOut() {
            return LoginService.isLoggingOutFlag;
        }

        LoginService.isTokenInStorage = isTokenInStorage;
        function isTokenInStorage() {
            return window.localStorage.getItem('token') !== null;
        }

        LoginService.isActiveUserInStorage = isActiveUserInStorage;
        function isActiveUserInStorage() {
            return window.localStorage.getItem('activeUser') !== null;
        }

        LoginService.isActiveUserOnModel = isActiveUserOnModel;
        function isActiveUserOnModel() {
            return LoginService.activeUser.id !== 0;
        }

        LoginService.isOffline = isOffline;
        function isOffline() {
            return !LoginService.isOnline();
        }

        LoginService.isOnline = isOnline;
        function isOnline() {
            return OfflineService.isOnline();
        }

        LoginService.isTokenOnModel = isTokenOnModel;
        function isTokenOnModel() {
            return DefaultVariableService.isDefined(LoginService.activeUser.token) && LoginService.activeUser.token !== null;
        }

        LoginService.loadActiveUser = loadActiveUser;
        function loadActiveUser(activeUserId) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'users');
            options = RequestManagementService.setAction(options, 'get');
            options = RequestManagementService.setSubAction(options, activeUserId);

            options = RequestManagementService.setParams(
                options,
                LoginService.getParams()
            );

            options = RequestManagementService.setMethod(options, 'POST');

            return DataManagementService.request(options).then(
                function(data) {
                    if (data) {
                        var users = DefaultVariableService.getArray(
                            data.users
                        );

                        if (users.length !== 0) {
                            return users[0];
                        }
                    }

                    return false;
                }
            );
        }

        LoginService.login = login;
        function login(activeUser) {
            LoginService.isLoggingInFlag = true;

            var loginData = {
                username: activeUser.username,
                password: activeUser.password
            };

            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'auth');
            options = RequestManagementService.setAction(options, 'login');

            options = RequestManagementService.setParams(
                options,
                LoginService.getParams()
            );

            options = RequestManagementService.setData(options, loginData);

            return DataManagementService.request(options).then(
                function(data) {
                    if (data) {
                        var users = DefaultVariableService.getArray(
                            data.users
                        );

                        if (users.length !== 0) {
                            LoginService.setActiveUser(users[0]);
                        }

                        var token = DefaultVariableService.getString(data.token);

                        LoginService.setToken(token);

                        RegistryService.notify(ON_LOGIN, true);

                        return LoginService.activeUser;
                    }

                    return false;
                }
            ).finally(
                function() {
                    LoginService.isLoggingInFlag = false;
                }
            );
        }

        LoginService.logout = logout;
        function logout() {
            if (LoginService.isOnline()) {
                LoginService.isLoggingOutFlag = true;
    
                var options = RequestManagementService.getRequest();
    
                options = RequestManagementService.setModel(options, 'auth');
                options = RequestManagementService.setAction(options, 'logout');
    
                options = RequestManagementService.setMethod(options, 'POST');
    
                return DataManagementService.request(options).then(
                    function(data) {
                        if (data) {
                            LoginService.onLogoutSuccess();
                        }
    
                        return false;
                    }
                ).finally(
                    function() {
                        LoginService.isLoggingOutFlag = false;
                    }
                );
            } else {
                return ErrorService.addError('CANNOT LOGOUT WHEN OFFLINE.');
            }
        }

        LoginService.onError = onError;
        function onError(data) {
            if (!DefaultVariableService.isDefined(data)) {
                data = 'AN UNKNOWN ERROR OCCURRED.';
            }

            if (data.status !== -1) {
                ErrorService.addError(data);
            }

            return false;
        }

        LoginService.onLogoutSuccess = onLogoutSuccess;
        function onLogoutSuccess() {
            LoginService.deleteActiveUser();
            LoginService.deleteToken();

            LoginService.reset();

            RegistryService.notify(ON_LOGOUT, false);
        }

        LoginService.register = register;
        function register(controller, options) {
            options = LoginService.getRegisterOptions(options);

            if (DefaultVariableService.isDefined(controller)) {
                var registry = [];

                var keys = Object.keys(options);

                var keysLength = keys.length;

                for (var i = 0; i < keysLength; i++) {
                    var key = keys[i];

                    var functionName = options[key];
                    if (functionName) {
                        if (DefaultVariableService.isBoolean(functionName)) {
                            if (LoginService.isLoggedIn() && !LoginService.isLoggingOut()) {
                                if (DefaultVariableService.isDefined(controller.init)) {
                                    controller.init();
                                }
                            }
                        } else if (DefaultVariableService.isString(functionName)) {
                            var callback = controller[functionName];

                            registry.push({
                                key:      key,
                                callback: callback
                            });
                        }
                    }
                }

                RegistryService.registerAll(registry);
            }
        }

        LoginService.reset = reset;
        function reset() {
            LoginService.resetActiveUser();

            LoginService.checkAuths = {};

            LoginService.isChecking = false;

            LoginService.isLoggingInFlag = false;

            LoginService.isLoggingOutFlag = false;
        }

        LoginService.resetActiveUser = resetActiveUser;
        function resetActiveUser() {
            LoginService.activeUser = DefaultVariableService.getObject();
            LoginService.activeUser.token = null;
        }

        LoginService.setActiveUser = setActiveUser;
        function setActiveUser(user) {
            if (DefaultVariableService.isObject(user)) {
                LoginService.activeUser = user;

                window.localStorage.setItem(
                    'activeUser',
                    JSON.stringify(user)
                );
            } else {
                LoginService.deleteActiveUser();
            }
        }

        LoginService.setToken = setToken;
        function setToken(token) {
            if (DefaultVariableService.isString(token)) {
                LoginService.activeUser.token = token;

                window.localStorage.setItem('token', token);
            } else {
                LoginService.deleteToken();
            }
        }

        LoginService.updateActiveUser = updateActiveUser;
        function updateActiveUser(activeUser) {
            activeUser = DefaultVariableService.getObject(activeUser);

            if (activeUser.id !== 0) {
                activeUser = DefaultVariableService.mergeObjects(
                    activeUser,
                    LoginService.activeUser
                );

                LoginService.setActiveUser(activeUser);
            }

            return LoginService.activeUser;
        }

        LoginService.reset();

        LoginService.checkAuth();

        DataManagementService.register(
            LoginService.checkResponse
        );

        return LoginService;
    }
})();

(function() {
    'use strict';

    angular.module('ionicLogin').controller(
        'IonicLoginController',
        IonicLoginController
    );

    IonicLoginController.$inject = [
        'DefaultVariableService',
        'IonicLoadingTemplateService',
        'LoginService',
        'RedirectService',
        '$scope'
    ];

    function IonicLoginController(
        DefaultVariableService,
        IonicLoadingTemplateService,
        LoginService,
        RedirectService,
        $scope
    ) {
        var IonicLoginController = this;
        
        var CHECK_AUTH_MESSAGE = 'AUTHORIZING...';
        var LOGIN_MESSAGE = 'LOGGING IN...';
        var LOGOUT_MESSAGE = 'LOGGING IN...';

        $scope.$watch(
            function() {
                return LoginService.isCheckingAuth();
            },
            function(isCheckingAuth) {
                IonicLoginController.isCheckingAuth = isCheckingAuth;

                IonicLoadingTemplateService.toggleTemplate(
                    IonicLoginController.checkAuthMessage,
                    isCheckingAuth
                );
            }
        );

        $scope.$watch(
            function() {
                return LoginService.isLoggedIn();
            },
            function(isLoggedIn) {
                IonicLoginController.isLoggedIn = isLoggedIn;
            }
        );

        $scope.$watch(
            function() {
                return LoginService.isLoggingIn();
            },
            function(isLoggingIn) {
                IonicLoginController.isLogginIn = isLoggingIn;

                IonicLoadingTemplateService.toggleTemplate(
                    LOGIN_MESSAGE,
                    isLoggingIn
                );
            }
        );

        $scope.$watch(
            function() {
                return LoginService.isLoggingOut();
            },
            function(isLoggingOut) {
                IonicLoginController.isLoggingOut = isLoggingOut;

                IonicLoadingTemplateService.toggleTemplate(
                    LOGOUT_MESSAGE,
                    isLoggingOut
                );
            }
        );

        IonicLoginController.goToForgotPassword = goToForgotPassword;
        function goToForgotPassword() {
            if (IonicLoginController.forgotPassword) {
                RedirectService.goTo(
                    IonicLoginController.forgotPassword
                );
            }
        }

        IonicLoginController.goToSignUp = goToSignUp;
        function goToSignUp() {
            if (IonicLoginController.signUp) {
                RedirectService.goTo(
                    IonicLoginController.signUp
                );
            }
        }

        IonicLoginController.login = login;
        function login() {
            return LoginService.login(IonicLoginController.activeUser).then(
                function(activeUser) {
                    if (activeUser) {
                        IonicLoginController.activeUser = activeUser;
                    } else {
                        IonicLoginController.resetPassword();
                    }

                    return activeUser;
                }
            );
        }

        IonicLoginController.logout = logout;
        function logout() {
            return LoginService.logout().then(
                function(response) {
                    if (response) {
                        IonicLoginController.reset();
                    }

                    return response;
                }
            );
        }

        IonicLoginController.reset = reset;
        function reset() {
            IonicLoginController.activeUser = {};
            IonicLoginController.activeUser.id = 0;

            IonicLoginController.checkAuthMessage = DefaultVariableService.get(
                IonicLoginController.checkAuthMessage,
                CHECK_AUTH_MESSAGE
            );

            IonicLoginController.image = DefaultVariableService.get(
                IonicLoginController.image,
                false
            );

            IonicLoginController.forgotPassword = DefaultVariableService.get(
                IonicLoginController.forgotPassword,
                false
            );

            IonicLoginController.loggingInMessage = DefaultVariableService.get(
                IonicLoginController.loggingInMessage,
                LOGIN_MESSAGE
            );

            IonicLoginController.loggingOutMessage = DefaultVariableService.get(
                IonicLoginController.loggingOutMessage,
                LOGOUT_MESSAGE
            );

            IonicLoginController.showPassword = false;

            IonicLoginController.signUp = DefaultVariableService.get(
                IonicLoginController.signUp,
                false
            );
        }

        IonicLoginController.resetPassword = resetPassword;
        function resetPassword() {
            IonicLoginController.activeUser.password = '';
        }

        IonicLoginController.toggleShowPassword = toggleShowPassword;
        function toggleShowPassword() {
            IonicLoginController.showPassword = !IonicLoginController.showPassword;
        }

        IonicLoginController.init = init;
        function init() {
            IonicLoginController.reset();
        }

        IonicLoginController.init();
    }
})();
(function() {
    'use strict';

    angular.module('ionicLogin').directive('ionicLogin', ionicLogin);

    function ionicLogin() {
        return {
            bindToController: {
                checkAuthMessage:  '@',
                image:             '@',
                forgotPassword:    '@',
                loggingInMessage:  '@',
                loggingOutMessage: '@',
                signUp:            '@'
            },
            controller:   'IonicLoginController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {},
            template:'<div class="container h-100"><div class="row align-items-center h-100"><form data-ng-submit="ctrl.login()" class="col col-xl-6 offset-xl-3"><div data-ng-if="ctrl.image" class="row"><div class="col text-center"><img data-ng-src="{{ ctrl.image }}" class="ionic-login-image"></div></div><label class="item item-input item-floating-label"><span class="input-label">USERNAME</span> <input type="text" placeholder="USERNAME" data-ng-model="ctrl.activeUser.username"></label> <label class="item item-input item-floating-label"><i class="icon placeholder-icon ionic-login-show-password-icon" data-ng-class="{ \'ion-eye\': ctrl.showPassword, \'ion-eye-disabled\': !ctrl.showPassword }" on-touch="ctrl.toggleShowPassword()"></i> <span class="input-label">PASSWORD</span> <input type="{{ ctrl.showPassword ? \'text\' : \'password\' }}" placeholder="PASSWORD" data-ng-model="ctrl.activeUser.password"></label> <button type="submit" class="button button-full button-balanced accept-button" data-ng-if="!ctrl.isLoggedIn" data-ng-disabled="ctrl.isLoggingIn">{{ ctrl.isLoggingIn ? \'LOGGING IN\' : \'LOGIN\' }}</button> <button class="button button-full button-assertive decline-button" data-ng-if="ctrl.isLoggedIn" data-ng-click="ctrl.logout()" data-ng-disabled="ctrl.isLoggingOut">{{ ctrl.isLoggingOut ? \'LOGGING OUT\' : \'LOGOUT\' }}</button> <button data-ng-if="ctrl.signUp && !ctrl.isLoggedIn" class="button button-full" data-ng-click="ctrl.goToSignUp()">SIGN UP</button> <button data-ng-if="ctrl.forgotPassword" class="button button-clear button-assertive ionic-forgot-password" data-ng-click="ctrl.goToForgotPassword()">FORGOT PASSWORD</button></form></div></div>'
        };
    }
})();